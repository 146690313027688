import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import {
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  IconButton,
  Typography,
  Toolbar,
  CardMedia,
  AppBar,
} from '@mui/material';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HomeIcon from '@mui/icons-material/Home';
import LoginIcon from '@mui/icons-material/Login';
import InfoIcon from '@mui/icons-material/Info';
import { useTheme } from '@mui/material/styles';

import Main from '../../../../main/Main';

import logo from '../../../../assets/graphics/apple.png';
import commonStyles from '../../../../commonUtils/commonStyles';
import { ROUTES_LIST } from '../../../../commonUtils/constants';

let drawerWidth = 180;
let theme;

function HeaderComponent(props) {
  const [width, setWidth] = useState(window.innerWidth);

  const handleResizeWindow = () => {
    //console.log('handleResizeWindow: ' + window.innerWidth);
    setWidth(window.innerWidth);
    if (window.innerWidth < 300) {
      drawerWidth = window.innerWidth - 60;
    }
  };

  useEffect(() => {
    handleResizeWindow();
    window.addEventListener('resize', handleResizeWindow);
  }, [width]);

  theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const navigate = useNavigate();

  const userRole = Main.APP_PARAMS.userRole;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleBackButton = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate('/', { replace: true }); // the current entry in the history stack will be replaced with the new one with { replace: true }
    }
  };

  const LeftHeaderButton = () => {
    switch (props.leftButtonType) {
      case 'ArrowBack':
        return (
          <IconButton color="inherit" onClick={handleBackButton} edge="start" style={getStyles().menuButton}>
            <ArrowBackIcon />
          </IconButton>
        );
      default:
        return (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            //className={clsx(getStyles().menuButton, open && getStyles().hide)}
            style={open === false ? getStyles().menuButton : getStyles().hide}
          >
            <MenuIcon />
          </IconButton>
        );
    }
  };

  const menuList = {
    line1: [
      {
        title: Main.getScreenNames()[0],
        icon: <HomeIcon style={getStyles().listIcon} />,
        link: ROUTES_LIST.home,
        role: ['user'],
      },
      {
        title: Main.getScreenNames()[1],
        icon: <LoginIcon style={getStyles().listIcon} />,
        link: ROUTES_LIST.login,
        role: ['user'],
      },
      {
        title: Main.getScreenNames()[2],
        icon: <InfoIcon style={getStyles().listIcon} />,
        link: ROUTES_LIST.about,
        role: ['user'],
      },
    ],
  };

  const menuListContent = Object.keys(menuList).map((key, i) => {
    const isLast = Object.keys(menuList).length === i + 1;

    return (
      <List key={i} component="nav">
        {menuList[key].map(({ title, icon, link, role }, index) => {
          if (role.includes(userRole)) {
            if (link) {
              return (
                <Link to={link} key={`${key}-${index}`} style={getStyles().listLink}>
                  <ListItem button>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText primary={title} style={commonStyles.text.robotoRegular16} />
                  </ListItem>
                </Link>
              );
            }
            return (
              <ListItem button key={`${key}-${index}`}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={title} style={commonStyles.text.robotoRegular16} />
              </ListItem>
            );
          }
        })}
        {!isLast && <Divider />}
      </List>
    );
  });
  //two nested appbars because of a bug in MUI AppBar (auto-resize on width change)
  return (
    <AppBar
      elevation={10}
      position="fixed"
      style={{
        ...getStyles().topAppBar,
        ...{ height: '65px' },
      }}
    >
      <AppBar
        //elevation={10}
        position="static"
        style={
          Main.APP_PARAMS.globalState.applicationMode === 'desktop'
            ? open === false
              ? getStyles().appBar
              : getStyles().appBarShift
            : getStyles().appBar
        }
      >
        <Toolbar style={getStyles().topToolbar}>
          <Grid container direction="row" style={getStyles().topToolBarLeftSide}>
            <CardMedia component="img" style={getStyles().logoImage} image={logo} />

            <Typography noWrap style={getStyles().logoText}>
              MY FRUITERUS
            </Typography>
          </Grid>
          <Grid container direction="row" justifyContent={'flex-end'} style={getStyles().topToolBarRightSide}>
            {Main.APP_PARAMS.globalState.applicationMode === 'desktop' ? (
              <Typography
                noWrap
                style={{
                  ...commonStyles.text.robotoBold16,
                  ...{ color: commonStyles.color.black, marginRight: '14px' },
                }}
              >
                {Main.getScreenNames()[props.screenNumber]}
              </Typography>
            ) : null}
            <LeftHeaderButton />
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer variant="persistent" anchor="right" open={open} PaperProps={{ style: { ...getStyles().drawerPaper } }}>
        <div style={getStyles().drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {open === true ? (
              <ChevronRightIcon
                style={{
                  color: commonStyles.color.black,
                }}
              />
            ) : (
              <ChevronLeftIcon style={{ color: commonStyles.color.black }} />
            )}
          </IconButton>
        </div>

        {menuListContent}
      </Drawer>
    </AppBar>
  );
}

const getStyles = () => {
  const styles = {
    topAppBar: {
      top: '0',
      bottom: 'auto',
      background: commonStyles.color.backgroundGrey,
    },
    topToolbar: {
      background: commonStyles.color.backgroundGrey,
      height: '9%',
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      //marginRight: '10px',
    },
    topToolBarLeftSide: {
      alignItems: 'center',
      //background: 'green',
    },
    topToolBarRightSide: {
      marginRight: '30px',
      alignItems: 'center',
      //background: 'yellow',
    },
    logoImage: {
      //marginTop: '5px',
      marginBottom: '5px',
      //marginRight: '10px',
      width: '40px',
      objectFit: 'initial',
      //background: 'red',
    },
    logoText: {
      ...commonStyles.text.impactRegular16,
      ...{ color: commonStyles.color.black, marginLeft: '10px' },
    },
    appBar: {
      //minHeight: '55px',
      height: '9%',
      //marginLeft: -25,
      //width: `calc(100% - ${drawerWidth}px)`,
      //background: commonStyles.color.firmGrey,
      //marginTop: '20px',
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      //height: '55px',
      height: '9%',
      //background: commonStyles.color.firmGrey,
      width: `calc(100% - ${drawerWidth}px)`,
      //marginTop: '20px',
      marginRight: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
      color: 'black',
    },
    hide: {
      display: 'none',
    },
    drawerPaper: {
      width: `${drawerWidth}px`,
      // height: '430px',
      //marginTop: '84px',
      border: '0px solid',
      borderTop: '1px solid',
      borderColor: 'rgba(0, 0, 0, 0.12)',
      boxShadow: '0px 5.5px 5px rgba(0,0,0,0.24), 0px 9px 18px rgba(0,0,0,0.18)',
    },
    drawerHeader: {
      display: 'flex',
      height: '55px',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      //...theme.mixins.toolbar,
      justifyContent: 'flex-start',
    },
    listIcon: {
      color: commonStyles.color.black,
    },
    listLink: {
      textDecoration: 'none',
      color: 'inherit',
    },
  };
  return styles;
};

export default HeaderComponent;
