import { styled } from '@mui/material/styles';
import MuiButton from '@mui/material/Button';
import commonStyles from '../../../../commonUtils/commonStyles';

export const ButtonComponent = styled(MuiButton)((props) => ({
  // Custom CSS
  width: (props) => (props.fullWidth ? '100%' : 'inherit'),
  minWidth: (props) => props.width || 200,
  borderRadius: 50,
  fontWeight: 400,
  fontSize: '16px',
  padding: '10px 14px',
  textTransform: 'none',
  color: props.variant === 'contained' && !props.disabled ? commonStyles.color.black : commonStyles.color.firmGrey,
  backgroundColor:
    props.variant === 'contained' && !props.disabled ? commonStyles.color.white : commonStyles.color.white,
  //borderColor: props.variant === 'outlined' && !props.disabled ? 'rgb(177, 174, 174)' : commonStyles.color.white,
  '&:hover': {
    backgroundColor: props.variant === 'contained' && !props.disabled ? 'rgb(230, 230, 230)' : commonStyles.color.white,
  },
}));
