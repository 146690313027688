import React, { useState, useEffect } from 'react';
//import { useNavigate } from 'react-router-dom';
import { Grid, Paper, Typography, Button } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
//import useServerRequest from '../../hooks/serverRequest';
//import { API_ENDPOINTS_LIST } from '../../commonUtils/constants';
//import { ROUTES_LIST } from '../../commonUtils/constants';

import HeaderComponent from '../Components/CommonComponents/HeaderComponent/HeaderComponent';
import DesktopMainComponent from './Desktop/MainComponent';
import MobileMainComponent from './Mobile/MainComponent';

import commonStyles from '../../commonUtils/commonStyles';

import Main from '../../main/Main';

const theme = createTheme({
  palette: {
    secondary: {
      main: commonStyles.color.blue,
    },
    primary: {
      main: commonStyles.color.firmGrey,
    },
    action: {
      disabledBackground: commonStyles.color.blueDisabled,
      disabled: commonStyles.color.white,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

export default function HomeScreen() {
  /*
  const navigate = useNavigate();
  
  const setGlobalLoading = (val) => {
    Main.APP_PARAMS.setGlobalState((prevState) => ({
      ...prevState,
      globalLoading: val,
    }));
  };
  */
  const [state, setState] = useState({
    testData: '',
  });

  //const { getRequest: loadInitialData } = useServerRequest(setGlobalLoading);
  //const { getRequest: uploadData } = useServerRequest(setGlobalLoading);

  useEffect(() => {
    //console.log('Load initial data');
    /*
    setState((prevState) => ({
      ...prevState,
      venueData: venueData,
      venueName: venueData.Name,
      venueDynamicComponents: dynamicComponentsArray,
    }));
    */
  }, []);

  useEffect(() => {
    //call function when something change in state
  }, [state]); //dependency added
  /*
  const updateText = (name, val) => {
    setState((prevState) => ({
      ...prevState,
      [name]: val,
    }));
  };
  */
  /*
  const handleSubmitButton = () => {
    loadInitialData(API_ENDPOINTS_LIST.getTestData, '', 'GET').then((res) => {
      //console.log('loadData result: ' + JSON.stringify(res));
      //console.log('loadTableData result: ' + JSON.stringify(res.clientsStatuses));

      setState((prevState) => ({
        ...prevState,
        testData: JSON.stringify(res),
      }));
    });
  };

  const handleSubmit3DButton = () => {
    navigate(ROUTES_LIST.render3Dmodel);
  };
  */

  return (
    <ThemeProvider theme={theme}>
      <HeaderComponent screenNumber={0} />
      <Paper style={getStyles().mainPaper} square={true} elevation={0}>
        {Main.APP_PARAMS.globalState.applicationMode === 'mobile' ? <MobileMainComponent /> : <DesktopMainComponent />}
      </Paper>
    </ThemeProvider>
  );
}

const getStyles = () => {
  const styles = {
    mainPaper: {
      marginTop: '64px',
      background: commonStyles.color.backgroundGrey,
    },
  };
  return styles;
};
