import React, { useState, useEffect } from 'react';
//import { useNavigate } from 'react-router-dom';
import { Grid, Typography, Button, CardMedia } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
//import useServerRequest from '../../hooks/serverRequest';
//import { API_ENDPOINTS_LIST } from '../../commonUtils/constants';
//import { ROUTES_LIST } from '../../commonUtils/constants';
import fruitsImage from '../../../../../assets/graphics/fruits_main.png';

import { ButtonComponent } from '../../../../Components/CommonComponents/ButtonComponent/ButtonComponent';

import commonStyles from '../../../../../commonUtils/commonStyles';

import Main from '../../../../../main/Main';

const theme = createTheme({
  palette: {
    secondary: {
      main: commonStyles.color.blue,
    },
    primary: {
      main: commonStyles.color.firmGrey,
    },
    action: {
      disabledBackground: commonStyles.color.blueDisabled,
      disabled: commonStyles.color.white,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

export default function IntroComponent() {
  /*
  const navigate = useNavigate();
  
  const setGlobalLoading = (val) => {
    Main.APP_PARAMS.setGlobalState((prevState) => ({
      ...prevState,
      globalLoading: val,
    }));
  };
  */
  const [state, setState] = useState({
    testData: '',
  });

  //const { getRequest: loadInitialData } = useServerRequest(setGlobalLoading);
  //const { getRequest: uploadData } = useServerRequest(setGlobalLoading);

  useEffect(() => {
    //console.log('Load initial data');
    /*
    setState((prevState) => ({
      ...prevState,
      venueData: venueData,
      venueName: venueData.Name,
      venueDynamicComponents: dynamicComponentsArray,
    }));
    */
  }, []);

  useEffect(() => {
    //call function when something change in state
  }, [state]); //dependency added
  /*
  const updateText = (name, val) => {
    setState((prevState) => ({
      ...prevState,
      [name]: val,
    }));
  };
  */

  const handleChangeButton = () => {
    //console.log('loadData result: ' + JSON.stringify(res));
    //console.log('loadTableData result: ' + JSON.stringify(res.clientsStatuses));
    /*
      setState((prevState) => ({
        ...prevState,
        testData: JSON.stringify(res),
      }));
    */
    Main.APP_PARAMS.setGlobalState((prevState) => ({
      ...prevState,
      applicationMode: 'mobile',
    }));
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={getStyles().centralPane}
      >
        <Grid item align="center">
          <CardMedia component="img" image={fruitsImage} style={getStyles().fruitsImage} />
        </Grid>
        <Grid item align="center" style={getStyles().nameContainer}>
          <Typography noWrap style={getStyles().nameText}>
            MY FRUITERUS
          </Typography>
        </Grid>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={3} align="center" />
          <Grid item xs={6} align="center">
            <Typography style={getStyles().commentText}>
              A light-hearted tool developed to help you better understand how fibroids are affecting your anatomy
            </Typography>
          </Grid>
          <Grid item xs={3} align="center" />
        </Grid>
        <Grid item style={{ marginTop: '20px' }}>
          <ButtonComponent variant={'contained'} disabled={false} onClick={() => handleChangeButton()}>
            Mobile version
          </ButtonComponent>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

const getStyles = () => {
  const styles = {
    centralPane: {
      //border: '1px solid',
      //borderColor: 'red',
      minHeight: `calc(100vh - 64px)`,
    },
    fruitsImage: {
      maxWidth: '1070px',
    },
    nameContainer: {
      marginTop: '70px',
    },
    nameText: {
      ...commonStyles.text.impactRegular55,
      ...{ color: commonStyles.color.black },
    },
    commentText: {
      ...commonStyles.text.robotoRegular16,
      ...{ color: commonStyles.color.black },
    },
    button: {
      maxWidth: '100px',
      margin: '10px',
      //marginLeft: '30px',
    },
  };
  return styles;
};
