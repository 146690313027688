import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material/';
import PropTypes from 'prop-types';

function LoaderComponent(props) {
  const { loading } = props;

  return (
    <Backdrop style={{ zIndex: 11500 }} open={loading}>
      <CircularProgress style={{ color: '#ffffff' }} />
    </Backdrop>
  );
}

LoaderComponent.propTypes = {
  loading: PropTypes.bool,
};
LoaderComponent.defaultProps = {
  loading: false,
};

export default LoaderComponent;
