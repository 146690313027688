export const ROUTES_LIST = {
  home: '/home',
  login: '/login',
  about: '/about',
  testDB: '/test-db',
  render3Dmodel: '/render-model',
};

export const API_ENDPOINTS_LIST = {
  //tests
  getTestData: '/test_db/users',
};
