/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import { useGLTF, PerspectiveCamera } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';

export default function Model({ ...props }) {
  const group = useRef();
  const { viewport } = useThree();
  const { nodes, materials } = useGLTF('./fibroid_tumor_case_2_rotated_upright.glb');
  return (
    <group ref={group} {...props} dispose={null} scale={viewport.width / 2}>
      <mesh
        geometry={nodes['female_body-1'].geometry}
        material={materials['color-6']}
        position={[0, -0.2, 0]}
        rotation={[-1.69, -0.03, -0.14]}
      />
      <group position={[-0.04, 0.05, -0.01]} rotation={[-1.69, -0.03, -0.14]}>
        <mesh geometry={nodes.mesh_1.geometry} material={materials['color-2']} />
        <mesh geometry={nodes.mesh_1_1.geometry} material={materials['color-2']} />
      </group>
      <mesh
        geometry={nodes['Fibroid_1-1'].geometry}
        material={materials.color}
        position={[-0.04, 0.05, -0.01]}
        rotation={[-1.69, -0.03, -0.14]}
      />
      <mesh
        geometry={nodes['Fibroid_3-1'].geometry}
        material={materials['color-7']}
        position={[-0.04, 0.05, -0.01]}
        rotation={[-1.69, -0.03, -0.14]}
      />
      <mesh
        geometry={nodes['Bladder-1'].geometry}
        material={materials['color-9']}
        position={[-0.04, 0.05, -0.01]}
        rotation={[-1.69, -0.03, -0.14]}
      />
      <mesh
        geometry={nodes['Fibroid_4-1'].geometry}
        material={materials['color-1']}
        position={[-0.04, 0.05, -0.01]}
        rotation={[-1.69, -0.03, -0.14]}
      />
      <mesh
        geometry={nodes['Endometrium-1'].geometry}
        material={materials['color-5']}
        position={[-0.04, 0.05, -0.01]}
        rotation={[-1.69, -0.03, -0.14]}
      />
      <mesh
        geometry={nodes['Fibroid_5-1'].geometry}
        material={materials['color-4']}
        position={[-0.04, 0.05, -0.01]}
        rotation={[-1.69, -0.03, -0.14]}
      />
      <mesh
        geometry={nodes['Fibroid_6-1'].geometry}
        material={materials['color-8']}
        position={[-0.04, 0.05, -0.01]}
        rotation={[-1.69, -0.03, -0.14]}
      />
      <mesh
        geometry={nodes['Fibroid_2-1'].geometry}
        material={materials['color-3']}
        position={[-0.04, 0.05, -0.01]}
        rotation={[-1.69, -0.03, -0.14]}
      />
      <PerspectiveCamera
        makeDefault={false}
        far={100}
        near={0.01}
        fov={20.26}
        position={[-1.54, 0.44, 1.24]}
        rotation={[-0.27, -0.85, -0.18]}
      />
    </group>
  );
}

useGLTF.preload('/fibroid_tumor_case_2_rotated_upright.glb');
