import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import '@fontsource/roboto'; // Defaults to weight 400.
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './index.css';
//import reportWebVitals from './reportWebVitals';

import { isMobile } from 'react-device-detect';

import Main from './main/Main';

import LoaderComponent from './screens/Components/CommonComponents/LoaderComponent/LoaderComponent';
import ToastComponent from './screens/Components/CommonComponents/ToastComponent/ToastComponent';
import HomeScreen from './screens/HomeScreen/HomeScreen';
import RenderSimple3DmodelScreen from './screens/Render3DmodelScreen/RenderSimple3DmodelScreen';
import RenderComplex3DmodelScreen from './screens/Render3DmodelScreen/RenderComplex3DmodelScreen';

import { ROUTES_LIST } from './commonUtils/constants';

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: '#00FFFF',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#11cb5f',
    },
  },
});

const App = () => {
  const deviceType = isMobile ? 'mobile' : 'desktop';

  const globalStateInit = {
    applicationMode: deviceType,
    toastOpen: false,
    toastAutohideDuration: 15000,
    toastStyle: 'success',
    toastMessage: '',
    globalLoading: false,
  };
  const [globalState, setGlobalState] = useState(globalStateInit);

  const APP_PARAMS = {
    userRole: 'user',
    setGlobalState: setGlobalState,
    globalState: globalState,
  };

  Main.APP_PARAMS = APP_PARAMS;

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setGlobalState((prevState) => ({
      ...prevState,
      toastOpen: false,
    }));
  };

  //common routes
  const commonRoutes = (
    <Routes>
      {/*!checkedToken && (
        <Route exact path="/">
          <AuthScreen />
        </Route>
      )*/}
      <Route exact path="/" element={<HomeScreen />} />
      <Route exact path={ROUTES_LIST.home} element={<HomeScreen />} />
      {/*<Route exact path={ROUTES_LIST.render3Dmodel} element={<RenderSimple3DmodelScreen />} />*/}
      <Route exact path={ROUTES_LIST.render3Dmodel} element={<RenderComplex3DmodelScreen />} />
    </Routes>
  );

  const normalMode = <Router>{commonRoutes}</Router>;
  //const maintenanceMode = <MaintenanceScreen />;

  return (
    <ThemeProvider theme={theme}>
      <LoaderComponent loading={globalState.globalLoading} />
      <ToastComponent
        open={globalState.toastOpen}
        close={handleToastClose}
        duration={globalState.toastAutohideDuration}
        styles={globalState.toastStyle}
        message={globalState.toastMessage}
      />
      {Main.APP_MODE === 'normal' ? normalMode : null}
    </ThemeProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
