import React, { useState, useEffect, useRef } from 'react';

//import { Grid, Paper, Typography, Button } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

//import { ROUTES_LIST } from '../../commonUtils/constants';

import { Canvas, useFrame, useThree } from '@react-three/fiber';

import commonStyles from '../../commonUtils/commonStyles';

import Main from '../../main/Main';

const theme = createTheme({
  palette: {
    secondary: {
      main: commonStyles.color.blue,
    },
    primary: {
      main: commonStyles.color.firmGrey,
    },
    action: {
      disabledBackground: commonStyles.color.blueDisabled,
      disabled: commonStyles.color.white,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

function Box(props) {
  const mesh = useRef();
  //const [state, setState] = useState({ isHovered: false, isActive: false });
  const [state, setState] = useState({ isActive: false });
  const { viewport } = useThree();

  useFrame(() => (mesh.current.rotation.x = mesh.current.rotation.y += 0.01));

  return (
    <mesh
      {...props}
      ref={mesh}
      scale={viewport.width / 5}
      onClick={(e) => setState({ ...state, isActive: !state.isActive })}
      //onPointerOver={(e) => setState({ ...state, isHovered: true })}
      //onPointerOut={(e) => setState({ ...state, isHovered: false })}
    >
      <boxBufferGeometry args={[1, 1, 1]} />
      <meshStandardMaterial color={state.isActive ? '#820263' : '#D90368'} />
    </mesh>
  );
}

export default function RenderSimple3DmodelScreen() {
  return (
    <Canvas style={getStyles().mainPaper}>
      <ambientLight intensity={0.5} />
      <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
      <pointLight position={[-10, -10, -10]} />
      <Box position={[0, 0, 0]} />
    </Canvas>
  );
}

const getStyles = () => {
  const styles = {
    mainPaper: { background: commonStyles.color.greyLight, width: '100vw', height: '100vh' },

    topTextGrid: {
      width: '100vw',
      height: '100vh',
      spacing: 0,
      justify: 'space-around',
      background: '#FFAA00',
    },
    topTextGridColumn: {
      //background: '#DDFF99',
      padding: '30px',
    },
  };
  return styles;
};
