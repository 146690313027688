import React, { useState, useEffect, useRef } from 'react';

import { Grid, Container, Typography, Button, Switch } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

//import { ROUTES_LIST } from '../../commonUtils/constants';

import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { ContactShadows, Environment, OrbitControls } from '@react-three/drei';
//do not forget to move .glb into /public !!!
import Model from '../../assets/3D_models/fibroid_tumor_case_2_rotated_upright/Fibroid_tumor_case_2_rotated_upright_JSX3D';

import commonStyles from '../../commonUtils/commonStyles';

import Main from '../../main/Main';

const theme = createTheme({
  palette: {
    secondary: {
      main: commonStyles.color.blue,
    },
    primary: {
      main: commonStyles.color.firmGrey,
    },
    action: {
      disabledBackground: commonStyles.color.blueDisabled,
      disabled: commonStyles.color.white,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

export default function RenderComplex3DmodelScreen() {
  const [state, setState] = useState({ currentView: 1 });

  const handleChangeButton = (num) => {
    //console.log('handleChangeButton');
    setState({ ...state, currentView: num });
  };

  const SingleView = () => {
    return (
      <Canvas style={getStyles().mainPaper1}>
        <ambientLight intensity={0.2} />
        <spotLight intensity={0.5} angle={0.1} penumbra={1} position={[10, 15, 10]} castShadow />
        <React.Suspense fallback={null}>
          <Model />
        </React.Suspense>
        <OrbitControls
          //minPolarAngle={0}
          //maxPolarAngle={Math.PI}
          //minAzimuthAngle={-Infinity}
          //maxAzimuthAngle={Infinity}
          //enableZoom={false}
          enablePan={false}
        />
      </Canvas>
    );
  };

  const MultipleViews = () => {
    return (
      <Container style={getStyles().mainPaper2} maxWidth={false}>
        <Grid container>
          <Grid container>
            <Grid item xs={6} align="center">
              <Typography style={{ color: 'white' }}>Axial</Typography>
              <Canvas
                style={{
                  background: 'black',
                  width: '400px',
                  height: '400px',
                  border: '1px solid',
                  borderColor: 'white',
                }}
                camera={{ position: [0, -30, 0], zoom: 2 }}
              >
                <ambientLight intensity={0.2} />
                <spotLight intensity={0.5} angle={0.1} penumbra={1} position={[10, 15, 10]} castShadow />
                <React.Suspense fallback={null}>
                  <Model />
                </React.Suspense>
              </Canvas>
            </Grid>
            <Grid item xs={6} align="center">
              <Typography style={{ color: 'white' }}>Coronal</Typography>
              <Canvas
                style={{
                  background: 'black',
                  width: '400px',
                  height: '400px',
                  border: '1px solid',
                  borderColor: 'white',
                }}
                camera={{ zoom: 2 }}
              >
                <ambientLight intensity={0.2} />
                <spotLight intensity={0.5} angle={0.1} penumbra={1} position={[10, 15, 10]} castShadow />
                <React.Suspense fallback={null}>
                  <Model />
                </React.Suspense>
              </Canvas>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: '10px', paddingBottom: '30px' }}>
            <Grid item xs={6} align="center">
              <Typography style={{ color: 'white' }}>Sagittal</Typography>
              <Canvas
                style={{
                  background: 'black',
                  width: '400px',
                  height: '400px',
                  border: '1px solid',
                  borderColor: 'white',
                }}
                camera={{ position: [30, 0, 0], zoom: 2 }}
              >
                <ambientLight intensity={0.2} />
                <spotLight intensity={0.5} angle={0.1} penumbra={1} position={[10, 15, 10]} castShadow />
                <React.Suspense fallback={null}>
                  <Model />
                </React.Suspense>
              </Canvas>
            </Grid>
            <Grid item xs={6} align="center">
              <Typography style={{ color: 'white' }}>3D rotation</Typography>
              <Canvas
                style={{
                  background: 'black',
                  width: '400px',
                  height: '400px',
                  border: '1px solid',
                  borderColor: 'white',
                }}
                camera={{ zoom: 2 }}
              >
                <ambientLight intensity={0.2} />
                <spotLight intensity={0.5} angle={0.1} penumbra={1} position={[10, 15, 10]} castShadow />
                <React.Suspense fallback={null}>
                  <Model />
                </React.Suspense>
                <OrbitControls enablePan={false} />
              </Canvas>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      {state.currentView === 1 ? <SingleView /> : <MultipleViews />}
      <Grid container style={getStyles().buttonsContainer}>
        <Grid item xs={12} align="center">
          <Button style={getStyles().button} variant="contained" onClick={() => handleChangeButton(1)}>
            Single Model View
          </Button>
          <Button style={getStyles().button} variant="contained" onClick={() => handleChangeButton(2)}>
            Multiple Model views
          </Button>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

const getStyles = () => {
  const styles = {
    mainPaper1: { background: commonStyles.color.black, width: '100vw', height: '100vh' },

    mainPaper2: { background: commonStyles.color.black, width: '100%', height: '100%' },

    topTextGrid: {
      width: '100vw',
      height: '100vh',
      spacing: 0,
      justify: 'space-around',
      background: '#FFAA00',
    },
    topTextGridColumn: {
      //background: '#DDFF99',
      padding: '30px',
    },
    buttonsContainer: {
      position: 'absolute',
      marginTop: '-200px',
    },
    buttonsContainer2: {
      position: 'absolute',
      marginTop: '-100px',
    },
    button: {
      maxWidth: '100px',
      margin: '10px',
      //marginLeft: '30px',
    },
  };
  return styles;
};
