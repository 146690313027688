var singleton = new (function () {
  /*
      this.publicTestVar = {
        prop1: 'test',
      };
      var privateTestVar = null;
    
      this.init = () => {
        privateTestVar = 'test private';
      };
    
      this.getTestVar = () => {
        return privateTestVar;
      };
    
      this.setTestVar = (val) => {
        privateTestVar = val;
      };
      */
  /*
      this.getfetchBaseURL = () => {
        return 'https://api.etrann-online.com'; //'https://cors-anywhere.herokuapp.com/' + 'http://18.191.31.212:3000';
      };
      */
  //require('dotenv').config();
  //current baseurls.
  //this.BASE_URL = 'https://970d-34-228-185-247.ngrok.io';
  //this.BASE_URL = 'https://corsanywhere.herokuapp.com/http://3.220.180.144';
  //this.BASE_URL = 'https://api.allorigins.win/raw?url=http://3.220.180.144';
  this.BASE_URL = 'https://myfruiterus-backend.com';
  this.APP_MODE = 'normal'; //normal or maintenance

  this.APP_PARAMS = {
    userRole: '',
    setGlobalState: (state) => {},
    globalState: {
      applicationMode: '',
      toastOpen: false,
      toastAutohideDuration: 15000,
      toastStyle: 'success',
      toastMessage: '',
      globalLoading: false,
    },
  };

  var screenNames = ['Home', 'Login', 'About'];

  this.getScreenNames = () => {
    return screenNames;
  };

  this.mainDataObj = {};

  //Init method
  this.init = () => {};
})();

export default singleton;
