import React, { useState, useEffect } from 'react';
//import { useNavigate } from 'react-router-dom';
import { Grid, Paper, Typography, Button } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
//import useServerRequest from '../../hooks/serverRequest';
//import { API_ENDPOINTS_LIST } from '../../commonUtils/constants';
//import { ROUTES_LIST } from '../../commonUtils/constants';
import IntroComponent from './Components/IntroComponent/IntroComponent';

import commonStyles from '../../../commonUtils/commonStyles';

import Main from '../../../main/Main';

const theme = createTheme({
  palette: {
    secondary: {
      main: commonStyles.color.blue,
    },
    primary: {
      main: commonStyles.color.firmGrey,
    },
    action: {
      disabledBackground: commonStyles.color.blueDisabled,
      disabled: commonStyles.color.white,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

export default function MainComponent() {
  /*
  const navigate = useNavigate();
  
  const setGlobalLoading = (val) => {
    Main.APP_PARAMS.setGlobalState((prevState) => ({
      ...prevState,
      globalLoading: val,
    }));
  };
  */
  const [state, setState] = useState({
    testData: '',
  });

  //const { getRequest: loadInitialData } = useServerRequest(setGlobalLoading);
  //const { getRequest: uploadData } = useServerRequest(setGlobalLoading);

  useEffect(() => {
    //console.log('Load initial data');
    /*
    setState((prevState) => ({
      ...prevState,
      venueData: venueData,
      venueName: venueData.Name,
      venueDynamicComponents: dynamicComponentsArray,
    }));
    */
  }, []);

  useEffect(() => {
    //call function when something change in state
  }, [state]); //dependency added
  /*
  const updateText = (name, val) => {
    setState((prevState) => ({
      ...prevState,
      [name]: val,
    }));
  };
  */

  return (
    <ThemeProvider theme={theme}>
      {/*<Typography style={{ marginTop: '100px' }}>Desktop</Typography>
      <Button style={getStyles().button} variant="contained" onClick={() => handleChangeButton()}>
        Switch to Mobile
        </Button>*/}
      <Grid container>
        <Grid item xs={6} align="center" style={getStyles().leftPane}>
          <IntroComponent />
        </Grid>
        <Grid item xs={6} align="center" style={getStyles().rightPane}></Grid>
      </Grid>
    </ThemeProvider>
  );
}

const getStyles = () => {
  const styles = {
    leftPane: {
      //border: '1px solid',
      //borderColor: 'red',
      minHeight: `calc(100vh - 64px)`,
    },
    rightPane: {
      //border: '1px solid',
      //borderColor: 'green',
      minHeight: `calc(100vh - 64px)`,
      background: commonStyles.color.backgroundBrown,
    },
    button: {
      maxWidth: '100px',
      margin: '10px',
      //marginLeft: '30px',
    },
  };
  return styles;
};
