import React from 'react';
import { Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';
import PropTypes from 'prop-types';

function ToastComponent(props) {
  const { open, close, duration, styles, message } = props;

  return (
    <Snackbar open={open} autoHideDuration={duration} onClose={close}>
      <Alert onClose={close} severity={styles}>
        {message}
      </Alert>
    </Snackbar>
  );
}

ToastComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  styles: PropTypes.string.isRequired, //possible styles: "success", "error", "warning", "info"
  duration: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
};

export default ToastComponent;
